import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Dropdown, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import NewAccordian from "../../../components/common/faqAccordionClone"
import Slide from "../../../components/utility/slide_content"
import SVGIcon from "../SVGIcon"
import Buttons from "../button"
import "./VerticalTabs.scss"

const VerticalTabs = props => {
  const { data } = props
  const { faqHeading,faqDescription, faqType, ctaText, buttonText, link, faqs } = data
  const { illustrationPattern } = faqs[0]
  const [faqIndex, setFaqIndex] = useState(0)

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const [currentValue, setCurrentValue] = useState("")

  useEffect(() => {
    setFaqIndex(0)
    setCurrentValue(faqs[0].question[0])
  }, [])

  const setActiveAndCurrentTab = (value, title) => {
    setFaqIndex(value)
    setCurrentValue(title)
  }

  const FadeChildren = props => {
    return (
      <Fade
        delay={props.delay ? props.delay : 300}
        duration={props.duration ? props.duration : 500}
        bottom
        distance={props.distance ? props.distance : "30px"}
      >
        {props.children}
      </Fade>
    )
  }

  return (
    <div className={`vertical-tabs faq ${faqType}`}>
      <div className="dot-bg mx-auto">
        <Container className="Layout-container mx-auto">
          <Slide delay="200">
            <h2 className="fw-600 mx-auto text-center font-roboto main-heading mb50">
              {faqHeading}
            </h2>
            {faqDescription && <p className="p16 main-description mx-auto text-center mb48">{faqDescription}</p>}
          </Slide>

          <div className="prelative">
            <Slide delay="200">
              {faqType === "Vertical_tab_v2" && (
                <SVGIcon
                  name="block/yellowCircle"
                  className="yellow-circle position-absolute d-dsk"
                />
              )}

              <Row
                className={`content-row prelative ${(faqType ===
                  "Vertical_tab_v1" ||
                  faqType === "Vertical_tab_v2" || faqType === "Vertical_tab_v3") &&
                  "w-100 mx-auto"}`}
              >
                <Col
                  lg={
                    faqType === "Vertical_tab_v1"
                      ? 3
                      : faqType === "Vertical_tab_v2" || faqType === "Vertical_tab_v3"
                      ? 4
                      : 5
                  }
                  className="left-col"
                >
                  <Slide delay="200">
                    {ctaText && ctaText.text && (
                      <div className="rich-text">{parse(ctaText.html)}</div>
                    )}

                    {(faqType === "Vertical_tab_v1" ||
                      faqType === "Vertical_tab_v2" || faqType === "Vertical_tab_v3") &&
                    screenWidth <= 425 ? (
                      <>
                        <div className="drp-down-wrapper">
                          <Dropdown id="drp-main">
                            <Dropdown.Toggle
                              variant="success"
                              id="drp-btn"
                              className="font-roboto fw-bold d-flex justify-content-between"
                            >
                              {currentValue}
                              <SVGIcon
                                name="nav-arrow-down"
                                className="arrow-down"
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu id="drp-menu">
                              {faqs[0].question.map((fpd, id) => {
                                return (
                                  <Dropdown.Item>
                                    <div
                                      onClick={() =>
                                        setActiveAndCurrentTab(id, fpd)
                                      }
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <p className="p16 font-roboto fw-bold list-item">
                                        {fpd}
                                      </p>
                                    </div>
                                  </Dropdown.Item>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    ) : (
                      <>
                        <NewAccordian
                          faqComponent={data}
                          callback={id => {
                            if (id !== undefined) {
                              sessionStorage.setItem("accpreindex", id)
                              setFaqIndex(id)
                            } else {
                              var accprevIndex = parseInt(
                                sessionStorage.getItem("accpreindex")
                              )
                              setFaqIndex(accprevIndex)
                            }
                          }}
                        />
                      </>
                    )}
                  </Slide>
                </Col>
                <Col
                  lg={
                    faqType === "Vertical_tab_v1"
                      ? 9
                      : faqType === "Vertical_tab_v2" || faqType === "Vertical_tab_v3"
                      ? 8
                      : 7
                  }
                  className={`${
                    faqType === "Vertical_tab_v1" ||
                    faqType === "Vertical_tab_v2" || faqType === "Vertical_tab_v3"
                      ? ""
                      : "d-dsk"
                  } right-col`}
                >
                  <Col
                    lg={faqType === "Vertical_tab_v1" ? 10 : 12}
                    className="child"
                  >
                    <Slide delay="200">
                      {faqs[0]?.description && faqs[0]?.description[faqIndex] && (
                        <FadeChildren distance="0" duration={500} delay={100}>
                          <>
                            <div className="p16">
                              <p className="description">
                                {faqs[0]?.description[faqIndex]}
                              </p>
                            </div>
                            {faqs[0].linkText[faqIndex] &&
                              faqs[0].linkText[faqIndex] !== "null" && (
                                <a
                                  href={`/${faqs[0].slug[faqIndex]}`}
                                  className="d-flex align-items-center link mb38"
                                >
                                  {faqs[0].linkText[faqIndex]}
                                  <SVGIcon
                                    name="hypertext-arrow-right"
                                    className="arrow-right"
                                  />
                                </a>
                              )}
                          </>
                        </FadeChildren>
                      )}
                    </Slide>

                    <div className="img-wrapper mx-auto prelative">
                      {faqs[0].images.map((imgUrl, idx) => (
                        <div
                          className={`${
                            faqIndex === idx
                              ? "fillanimae"
                              : "fade-out-effect dspnone"
                          } accordion-img-wrapper-clone  position-absolute`}
                        >
                          {illustrationPattern === "pattern_1" && (
                            <>
                              <SVGIcon
                                name="block/purpleLine"
                                className="position-absolute purple-line"
                              />
                              <SVGIcon
                                name="block/redScribble"
                                className="position-absolute red-scribble"
                              />
                            </>
                          )}

                          {illustrationPattern === "pattern_3" && (
                            <SVGIcon
                              name="block/greenScribble"
                              className="position-absolute green-scribble"
                            />
                          )}

                          <div className="faq-image-wrapper">
                            <Fade
                              duration={
                                faqType === "Vertical_tab_v1" ||
                                faqType === "Vertical_tab_v2" || faqType === "Vertical_tab_v3"
                                  ? 1000
                                  : 500
                              }
                              delay={
                                (faqType === "Vertical_tab_v1" ||
                                  faqType === "Vertical_tab_v2" || faqType === "Vertical_tab_v3") &&
                                250
                              }
                            >
                              {imgUrl.fileName && imgUrl.fileName.split(".").pop() === "mp4" ? (
                                <video
                                  height="100%"
                                  width="100%"
                                  loop="true"
                                  autoplay="autoplay"
                                  muted
                                  className="br-10 faq-video"
                                >
                                  <source
                                    type="video/mp4"
                                    src={imgUrl.url}
                                  ></source>
                                </video>
                              ) : (
                                <img
                                  src={imgUrl.url}
                                  className="w-100 faq-image br-10"
                                  alt="TicketingImg"
                                />
                              )}
                            </Fade>
                          </div>

                          <div className="prelative" style={{ zIndex: "-1" }}>
                            {illustrationPattern === "pattern_2" && (
                              <SVGIcon
                                name="block/orangeSpiral"
                                className="position-absolute orange-spiral"
                              />
                            )}
                            {illustrationPattern === "pattern_3" && (
                              <SVGIcon
                                name="block/yellowCircle"
                                className="position-absolute yellow-circle"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Col>
              </Row>

              {faqType === "Vertical_tab_v2" && (
                <SVGIcon
                  name="block/redScribble"
                  className="position-absolute purple-scribble d-dsk"
                />
              )}
            </Slide>
          </div>

          <Slide delay="200">
            {buttonText && (
              <div className="text-center mt40 more-btn mx-auto">
                <Buttons
                  theme={`secondary-new`}
                  text={buttonText}
                  link={link}
                />
              </div>
            )}
          </Slide>
        </Container>
      </div>
    </div>
  )
}

export default VerticalTabs
